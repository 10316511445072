import React, {useContext, useEffect, useState} from 'react'
import { UserContext } from "./provider/UserProvider";
import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';
import Leaderboard from './pages/Leaderboard';
import LoginRouting from './pages/LoginRouting'
import DashboardRouting from './pages/DashboardRouting';
import PlayerPointsBoard from './pages/PlayerPointsBoard';
// import Transfers from './pages/Transfers';
// import MatchDataForm from './pages/MatchDataForm';
// import LoginSignUp from './pages/LoginSignUp';
// import Dashboard from './pages/Dashboard';
// import { getUserTeamByUID, doesUserExist, addNewUser } from './components/Firebase';
import { getUserTeamByUID, doesUserExist } from './components/Firebase';

// import TransfersRouting from './pages/TransfersRouting';


export default function AppRoute() {

    const user = useContext(UserContext)

    const [userExist, setUserExist] = useState(false)
    const [userTeam, setUserTeam] = useState(null)



    useEffect(() => {
        let isMounted = true;               // note mutable flag
        if (user !== null) {
            doesUserExist(user).then((exists) => {
                if (isMounted) setUserExist(exists);    // add conditional check
            })
        }

        return () => { isMounted = false }; // cleanup toggles value, if unmounted
    }, [user]);

    useEffect(() => {
        if (userExist) {
            // send stuff to dashboard props
            getUserTeamByUID(user).then((team) => {
                setUserTeam(team)
            })
        }
    }, [userExist, user])
    console.log(userTeam)

    // async function createNewUser(teamName, team, budget) {
    //     await addNewUser(user, teamName, team, budget)
    //     setUserExist(true)
    // } 
    
    return (
        <Router>
            <Routes>
                <Route exact path='/leaderboard' element={<Leaderboard />} />
                {/* <Route exact path='/admin' element={<MatchDataForm />} /> */}
                <Route exact path='/players' element={<PlayerPointsBoard />} />
                {/* <Route exact path='/transfers' element={<Transfers userTeam={userTeam} user={user}/>} /> */}
                
                {/* prevent new user creation */ }

                <Route exact path='/' element={<DashboardRouting />}>
                </Route>

                <Route exact path='/login' element={<LoginRouting />}>
                </Route>



                {/* enable new user creation */ }
                {/* <Route exact path='/' element={<DashboardRouting exist={userExist} create={createNewUser} />}>
                    <Route exact path='/' element={<Dashboard userTeam={userTeam} uid={user}/>} />
                </Route>
                <Route exact path='/login' element={<LoginRouting exist={userExist} create={createNewUser} />}>
                    <Route exact path='/login' element={<LoginSignUp />} />
                </Route> */}

                {/* possible transfer routing fix */ }
                {/* <Route exact path='/transfers' element={<TransfersRouting />}>
                </Route> */}
            </Routes>
        </Router>
    )
}