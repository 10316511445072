import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Nav } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Button, Modal } from 'react-bootstrap';
import { FaDiscord, FaTwitter, FaCoffee } from 'react-icons/fa';
import { AiFillMessage } from 'react-icons/ai';
import { Text, Linking } from 'react-native'


export default function Banner() {
  const [showModal, setShowModal] = useState(false) //render when the page first opens
  const [showMessage, setShowMessage] = useState(false)

  return (
    <Navbar variant="dark">
      <Navbar.Brand href="/">
        <h1 style={{ paddingLeft: "15%" }}>
          <Image
            style={{ width: 50, height: 50 }}
            src="https://owcdn.net/img/6009f963577f4.png"
          />
          { } Fantasy VCT
        </h1>
      </Navbar.Brand>

      <div style={{ paddingLeft: "6%" }}>
        <Nav className="me-auto">

          <Button style={{ maxHeight: "40px" }} variant="warning" onClick={() => setShowModal(true)}>
            Rules
          </Button>
          <Button style={{ maxHeight: "40px" }} variant="outline-warning" onClick={() => setShowMessage(true)}>
            <AiFillMessage size={25}/>
          </Button>

          <Nav.Link href="/">&nbsp;&nbsp; My Team &nbsp;&nbsp;</Nav.Link>
          <Nav.Link href="/leaderboard">&nbsp;&nbsp; Leaderboard &nbsp;&nbsp;</Nav.Link>
          <Nav.Link href="/players">&nbsp;&nbsp; Players &nbsp;&nbsp;</Nav.Link>
          {/* <Nav.Link href="/transfers">&nbsp;&nbsp; Transfers &nbsp;&nbsp;</Nav.Link> */}
          <Nav.Link href="https://discord.gg/FvAgKUYCbP">&nbsp;&nbsp;
            <FaDiscord size={30} />
            &nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link href="https://twitter.com/FantasyVCT">&nbsp;&nbsp;
            <FaTwitter size={30} />
            &nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link href="https://ko-fi.com/fantasyvct">&nbsp;&nbsp;
            <FaCoffee size={30} />
            &nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link href="https://na.fantasyvct.com" >&nbsp;&nbsp; <Image style ={{height: 30}} src="https://i.imgur.com/TWeAL0b.png"/> &nbsp;&nbsp;</Nav.Link>

          <Modal show={showMessage} onClick={() => setShowMessage(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Messages & Updates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>
                <strong>June 27: </strong> With this last round of matches, we've completed our competition for VCT Challengers 2! XSET and Fnatic take the #1 spots going into Copenhagen in what looks to be the most promising VCT tournament we've ever had.
                <br/>
                <br/>
                The winners for NA are <br />
                🥇 : DELUSIONAL_POTTER_FAN ^_^ (on yt) (6693) <br />
                🥈 : the boys (6662) <br />
                🥉 : GOATED (6560) <br />
                <br/>
                <br/>
                The winners for EMEA are <br />
                🥇 : Lione (7101) <br />
                🥈 : #FNCWIN (6901) <br />
                🥉 : dfg (6586) <br />
                <br />
                I want to thank everybody for participating and a special shoutout to all of you guys chatting it up in the discord. We've got a cool little community going on here and hopefully we continue to grow.
                <br />
                <br />
                We'll be back for Masters 2 Copenhagen with a similar format to how Reykjavik worked. I'm currently working on revamping the competition format soon, and I'll be back with updates on that.
                <br />
                <br />
                If you are one of the winners you can DM me on Discord. Otherwise I will be reaching out in the next few days with details on how to collect your prize.
              </p>
              <p>
                <strong>May 11: </strong> Welcome back to Fantasy VCT! This tab will continue to be where we communicate on the site itself. 
                If you want to chat to the team, other participants, or give feedback/input, join our <Text style ={{color: 'blue', fontSize: 15}}onPress={() => Linking.openURL("https://discord.gg/FvAgKUYCbP")}> Discord Server </Text>
                <br/>
                <br/>
                Members of the server got the chance to vote on player prices for this competition, which will continue in the future so make sure to get in and give your input!
                <br/>
                <br/>
                We also have a <Text style ={{color: 'blue', fontSize: 15}}onPress={() => Linking.openURL("https://twitter.com/FantasyVCT")}> Twitter </Text> where we post general updates.
                <br/>
                <br/>
                As always, thanks for playing and good luck!
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowMessage(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showModal} onClick={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Fantasy VCT Rules</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5> <strong>How the team selection works: </strong></h5>
              <p>
                  - You have a salary budget of 13000 credits to recruit your players.<br />
                  - You must recruit 2 players for each role (Duelist, Initiator, Sentinel, Controller).<br />
                  - The roles each player can be recruited as is listed.<br />
                  <strong>*NOTE: Because of the delay in opening this season's competition, team selection will remain open until 
                   the end of the first matchweek. </strong>
              </p>
              <br />
              <h5><strong>How the fantasy points work: </strong></h5>
              <p>
                  - Each player will earn you fantasy points every matchweek through the group stage (5 match days) and then the playoffs (a cumulative final match day)
              </p>
              <p>
                  <br />
                  - Players earn points through combat stats (K/D/A, FK/FD, ADR) and round stats (Rounds won, Rounds lost).
                  <br />
                  - The points tally for a match day is an average of points earned per map played.
                  <br />
                  <br />
                  - Points Calculation: <br />
                  <em>
                  [+10 Points per kill, +5 Points per assists, +10 Points per First Kill, +ADR/10] <br />
                  [-5 Points per death, -10 Points per First Death] <br />
                  [+5 Points per Round win, -5 Points per Round loss] <br />
                  <br />
                  </em>
                  <br />
                  <br />
                  <strong>
                  Your final points tally is the total sum of all points earned by players across every (6) match day of the event
                  </strong>
              </p>
              <br />
              <br />
              <br />
              <div style={{ textAlign: "center" }}>
                  <h3> The Winners’ prize pool is: </h3>
                  <h4>1st Place: 2000VP ($20)</h4>
                  <h5>2nd Place: 1000VP ($10)</h5>
                  <h6>3rd Place: 1000VP ($10)</h6>
                  *note: you must have paypal to receive your payment
                  <br />
                  <p><em>(Must have a PayPal in order to claim prize.)</em>  </p>
                  <br />
                  <h6> Thank you for trying out our Fantasy VCT!</h6>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>



        </Nav>
      </div>
    </Navbar >
  )
}