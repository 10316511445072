import React, { useContext, useEffect, useState } from 'react'
// import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from '../provider/UserProvider'
import { doesUserExist } from '../components/Firebase'
import Leaderboard from './Leaderboard'
import LoginSignUp from './LoginSignUp'
// import SearchPlayers from '../components/SearchPlayers'

export default function LoginRouting(props) {
    const user = useContext(UserContext)
    const [userExist, setUserExist] = useState(false)

    useEffect(() => {
        let isMounted = true;               // note mutable flag
        if (user !== null) {
            doesUserExist(user).then((exists) => {
                if (isMounted) setUserExist(exists);    // add conditional check
            })
        }

        return () => { isMounted = false }; // cleanup toggles value, if unmounted
    }, [user]);


    return user ? userExist ? <Navigate to='/' /> : <Leaderboard /> : <LoginSignUp />
    // return user ? props.exist ? <Navigate to='/'/> : <SearchPlayers createNewUser={props.create}/> : <Outlet/>
} 