import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card"
import StatTable from "../components/StatTable";
import { getUserMatchInfo, getUserTeamName } from "../components/Firebase";
import Banner from "../components/Banner";

// function handleSelect(key) {
//   var matchDayNum = "MatchDay" + "" + key;
//   console.log(matchDayNum)
// }

export default function Dashboard(props) {

  var totalDefault = {
    duelist_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    duelist_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    controller_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    controller_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    initiator_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    initiator_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    sentinel_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    sentinel_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    bench_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    bench_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    bench_3: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 }
  }

  const [userTeam, setUserTeam] = useState(props.userTeam)
  const [newTransfers, setNewTransers] = useState([])
  const [matchday1, setMatchDay1] = useState(null)
  const [matchday2, setMatchDay2] = useState(null)
  const [matchday3, setMatchDay3] = useState(null)
  const [matchday4, setMatchDay4] = useState(null)
  const [matchday5, setMatchDay5] = useState(null)
  const [matchday6, setMatchDay6] = useState(null)
  const [totalStat, setTotalStat] = useState(totalDefault)
  const [userTeamName, setUserTeamName] = useState("")

  async function setData(matchNum, data) {
    switch (matchNum) {
      case (1):
        await setMatchDay1(data)
        break
      case (2):
        await setMatchDay2(data)
        break
      case (3):
        await setMatchDay3(data)
        break
      case (4):
        await setMatchDay4(data)
        break
      case (5):
        await setMatchDay5(data)
        break
      case (6):
        await setMatchDay6(data)
        break
      default: return
    }
  }

  useEffect(() => {
    setUserTeam(props.userTeam)
  }, [props.userTeam])

  useEffect(() => {
    if (userTeam !== null) {
      setNewTransers(userTeam.new_transfers)
    }
  }, [userTeam])

  useEffect(() => {
    
    async function fetchData() {
      try {
        var name = getUserTeamName(props.uid)
        name.then((value) => {
          setUserTeamName(value)
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [props.uid])

  useEffect(() => {
    if (userTeam !== null) {
      
      async function fetchMatchData(match, matchNum) {
        var duelist1 = await getUserMatchInfo(match, userTeam.duelist_1.team, userTeam.duelist_1.name)
        var duelist2 = await getUserMatchInfo(match, userTeam.duelist_2.team, userTeam.duelist_2.name)
        var controller1 = await getUserMatchInfo(match, userTeam.controller_1.team, userTeam.controller_1.name)
        var controller2 = await getUserMatchInfo(match, userTeam.controller_2.team, userTeam.controller_2.name)
        var initiator1 = await getUserMatchInfo(match, userTeam.initiator_1.team, userTeam.initiator_1.name)
        var initiator2 = await getUserMatchInfo(match, userTeam.initiator_2.team, userTeam.initiator_2.name)
        var sentinel1 = await getUserMatchInfo(match, userTeam.sentinel_1.team, userTeam.sentinel_1.name)
        var sentinel2 = await getUserMatchInfo(match, userTeam.sentinel_2.team, userTeam.sentinel_2.name)
        var bench1 = {a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0}
        var bench2 = {a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0}
        var bench3 = {a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0}

        if (userTeam.bench_1 && userTeam.bench_2 && userTeam.bench_3) {
          if (userTeam.bench_1.name !== "") {
            bench1 = await getUserMatchInfo(match, userTeam.bench_1.team, userTeam.bench_1.name)
          }
          if (userTeam.bench_2.name !== "") {
            bench2 = await getUserMatchInfo(match, userTeam.bench_2.team, userTeam.bench_2.name)
          }
          if (userTeam.bench_3.name !== "") {
            bench3 = await getUserMatchInfo(match, userTeam.bench_3.team, userTeam.bench_3.name)
          }
        } else {
          //console.log("no bench")
        }
        

        const matchData = {
          duelist_1: duelist1,
          duelist_2: duelist2,
          controller_1: controller1,
          controller_2: controller2,
          initiator_1: initiator1,
          initiator_2: initiator2,
          sentinel_1: sentinel1,
          sentinel_2: sentinel2,
          bench_1: bench1,
          bench_2: bench2,
          bench_3: bench3
        }
        await setData(matchNum, matchData);
      }
      fetchMatchData("MatchDay1", 1);
      fetchMatchData("MatchDay2", 2);
      fetchMatchData("MatchDay3", 3);
      fetchMatchData("MatchDay4", 4);
      fetchMatchData("MatchDay5", 5);
      fetchMatchData("MatchDay6", 6);
    }

  }, [userTeam])

  useEffect(() => {
    var defaultTeam = {
      duelist_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      duelist_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      controller_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      controller_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      initiator_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      initiator_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      sentinel_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      sentinel_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      bench_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      bench_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      bench_3: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 }
    }

    
    if (matchday1 !== null && matchday2 !== null && matchday3 !== null && matchday4 !== null && matchday5 !== null && matchday6 !== null && userTeam !== null && userTeam.new_transfers !== null) {
      //console.log(userTeam.new_transfers)
      // console.log(userTeam.new_transfers.includes(userTeam.controller_1.name))

      if (userTeam.new_transfers.includes(userTeam.controller_1.name)) {
        defaultTeam.controller_1.player_name = userTeam.controller_1.name
        defaultTeam.controller_1.k = matchday4.controller_1.k + matchday5.controller_1.k + matchday6.controller_1.k
        defaultTeam.controller_1.d = matchday4.controller_1.d + matchday5.controller_1.d + matchday6.controller_1.d
        defaultTeam.controller_1.a = matchday4.controller_1.a + matchday5.controller_1.a + matchday6.controller_1.a
        defaultTeam.controller_1.fd = matchday4.controller_1.fd + matchday5.controller_1.fd + matchday6.controller_1.fd
        defaultTeam.controller_1.fk = matchday4.controller_1.fk + matchday5.controller_1.fk + matchday6.controller_1.fk
        defaultTeam.controller_1.points = matchday4.controller_1.points + matchday5.controller_1.points + matchday6.controller_1.points
      } else {
        defaultTeam.controller_1.player_name = userTeam.controller_1.name
        defaultTeam.controller_1.k = matchday1.controller_1.k + matchday2.controller_1.k + matchday3.controller_1.k + matchday4.controller_1.k + matchday5.controller_1.k + matchday6.controller_1.k 
        defaultTeam.controller_1.d = matchday1.controller_1.d + matchday2.controller_1.d + matchday3.controller_1.d + matchday4.controller_1.d + matchday5.controller_1.d + matchday6.controller_1.d 
        defaultTeam.controller_1.a = matchday1.controller_1.a + matchday2.controller_1.a + matchday3.controller_1.a + matchday4.controller_1.a + matchday5.controller_1.a + matchday6.controller_1.a 
        defaultTeam.controller_1.fd = matchday1.controller_1.fd + matchday2.controller_1.fd + matchday3.controller_1.fd + matchday4.controller_1.fd + matchday5.controller_1.fd + matchday6.controller_1.fd 
        defaultTeam.controller_1.fk = matchday1.controller_1.fk + matchday2.controller_1.fk + matchday3.controller_1.fk + matchday4.controller_1.fk + matchday5.controller_1.fk + matchday6.controller_1.fk 
        defaultTeam.controller_1.points = matchday1.controller_1.points + matchday2.controller_1.points + matchday3.controller_1.points + matchday4.controller_1.points + matchday5.controller_1.points + matchday6.controller_1.points 
      }
      
      if (userTeam.new_transfers.includes(userTeam.controller_2.name)) {
        defaultTeam.controller_2.player_name = userTeam.controller_2.name
        defaultTeam.controller_2.k = matchday4.controller_2.k + matchday5.controller_2.k + matchday6.controller_2.k
        defaultTeam.controller_2.d = matchday4.controller_2.d + matchday5.controller_2.d + matchday6.controller_2.d
        defaultTeam.controller_2.a = matchday4.controller_2.a + matchday5.controller_2.a + matchday6.controller_2.a
        defaultTeam.controller_2.fd = matchday4.controller_2.fd + matchday5.controller_2.fd + matchday6.controller_2.fd
        defaultTeam.controller_2.fk = matchday4.controller_2.fk + matchday5.controller_2.fk + matchday6.controller_2.fk
        defaultTeam.controller_2.points = matchday4.controller_2.points + matchday5.controller_2.points + matchday6.controller_2.points  
      } else {
        defaultTeam.controller_2.player_name = userTeam.controller_2.name
        defaultTeam.controller_2.k = matchday1.controller_2.k + matchday2.controller_2.k + matchday3.controller_2.k + matchday4.controller_2.k + matchday5.controller_2.k + matchday6.controller_2.k 
        defaultTeam.controller_2.d = matchday1.controller_2.d + matchday2.controller_2.d + matchday3.controller_2.d + matchday4.controller_2.d + matchday5.controller_2.d + matchday6.controller_2.d
        defaultTeam.controller_2.a = matchday1.controller_2.a + matchday2.controller_2.a + matchday3.controller_2.a + matchday4.controller_2.a + matchday5.controller_2.a + matchday6.controller_2.a 
        defaultTeam.controller_2.fd = matchday1.controller_2.fd + matchday2.controller_2.fd + matchday3.controller_2.fd + matchday4.controller_2.fd + matchday5.controller_2.fd + matchday6.controller_2.fd 
        defaultTeam.controller_2.fk = matchday1.controller_2.fk + matchday2.controller_2.fk + matchday3.controller_2.fk + matchday4.controller_2.fk + matchday5.controller_2.fk + matchday6.controller_2.fk 
        defaultTeam.controller_2.points = matchday1.controller_2.points + matchday2.controller_2.points + matchday3.controller_2.points + matchday4.controller_2.points + matchday5.controller_2.points + matchday6.controller_2.points  
      }

      if (userTeam.new_transfers.includes(userTeam.duelist_1.name)) {
        defaultTeam.duelist_1.player_name = userTeam.duelist_1.name
        defaultTeam.duelist_1.k = matchday4.duelist_1.k + matchday5.duelist_1.k + matchday6.duelist_1.k
        defaultTeam.duelist_1.d = matchday4.duelist_1.d + matchday5.duelist_1.d + matchday6.duelist_1.d
        defaultTeam.duelist_1.a = matchday4.duelist_1.a + matchday5.duelist_1.a + matchday6.duelist_1.a
        defaultTeam.duelist_1.fd = matchday4.duelist_1.fd + matchday5.duelist_1.fd + matchday6.duelist_1.fd
        defaultTeam.duelist_1.fk = matchday4.duelist_1.fk + matchday5.duelist_1.fk + matchday6.duelist_1.fk
        defaultTeam.duelist_1.points = matchday4.duelist_1.points + matchday5.duelist_1.points + matchday6.duelist_1.points  
      } else {
        defaultTeam.duelist_1.player_name = userTeam.duelist_1.name
        defaultTeam.duelist_1.k = matchday1.duelist_1.k + matchday2.duelist_1.k + matchday3.duelist_1.k + matchday4.duelist_1.k + matchday5.duelist_1.k + matchday6.duelist_1.k
        defaultTeam.duelist_1.d = matchday1.duelist_1.d + matchday2.duelist_1.d + matchday3.duelist_1.d + matchday4.duelist_1.d + matchday5.duelist_1.d + matchday6.duelist_1.d 
        defaultTeam.duelist_1.a = matchday1.duelist_1.a + matchday2.duelist_1.a + matchday3.duelist_1.a + matchday4.duelist_1.a + matchday5.duelist_1.a + matchday6.duelist_1.a 
        defaultTeam.duelist_1.fd = matchday1.duelist_1.fd + matchday2.duelist_1.fd + matchday3.duelist_1.fd + matchday4.duelist_1.fd + matchday5.duelist_1.fd + matchday6.duelist_1.fd
        defaultTeam.duelist_1.fk = matchday1.duelist_1.fk + matchday2.duelist_1.fk + matchday3.duelist_1.fk + matchday4.duelist_1.fk + matchday5.duelist_1.fk + matchday6.duelist_1.fk
        defaultTeam.duelist_1.points = matchday1.duelist_1.points + matchday2.duelist_1.points + matchday3.duelist_1.points + matchday4.duelist_1.points + matchday5.duelist_1.points + matchday6.duelist_1.points 
      }

      if (userTeam.new_transfers.includes(userTeam.duelist_2.name)) {
        defaultTeam.duelist_2.player_name = userTeam.duelist_2.name
        defaultTeam.duelist_2.k = matchday4.duelist_2.k + matchday5.duelist_2.k + matchday6.duelist_2.k
        defaultTeam.duelist_2.d = matchday4.duelist_2.d + matchday5.duelist_2.d + matchday6.duelist_2.d
        defaultTeam.duelist_2.a = matchday4.duelist_2.a + matchday5.duelist_2.a + matchday6.duelist_2.a
        defaultTeam.duelist_2.fd = matchday4.duelist_2.fd + matchday5.duelist_2.fd + matchday6.duelist_2.fd
        defaultTeam.duelist_2.fk = matchday4.duelist_2.fk + matchday5.duelist_2.fk + matchday6.duelist_2.fk
        defaultTeam.duelist_2.points = matchday4.duelist_2.points + matchday5.duelist_2.points + matchday6.duelist_2.points  
      } else {
        defaultTeam.duelist_2.player_name = userTeam.duelist_2.name
        defaultTeam.duelist_2.k = matchday1.duelist_2.k + matchday2.duelist_2.k + matchday3.duelist_2.k + matchday4.duelist_2.k + matchday5.duelist_2.k + matchday6.duelist_2.k
        defaultTeam.duelist_2.d = matchday1.duelist_2.d + matchday2.duelist_2.d + matchday3.duelist_2.d + matchday4.duelist_2.d + matchday5.duelist_2.d + matchday6.duelist_2.d
        defaultTeam.duelist_2.a = matchday1.duelist_2.a + matchday2.duelist_2.a + matchday3.duelist_2.a + matchday4.duelist_2.a + matchday5.duelist_2.a + matchday6.duelist_2.a
        defaultTeam.duelist_2.fd = matchday1.duelist_2.fd + matchday2.duelist_2.fd + matchday3.duelist_2.fd + matchday4.duelist_2.fd + matchday5.duelist_2.fd + matchday6.duelist_2.fd
        defaultTeam.duelist_2.fk = matchday1.duelist_2.fk + matchday2.duelist_2.fk + matchday3.duelist_2.fk + matchday4.duelist_2.fk + matchday5.duelist_2.fk + matchday6.duelist_2.fk 
        defaultTeam.duelist_2.points = matchday1.duelist_2.points + matchday2.duelist_2.points + matchday3.duelist_2.points + matchday4.duelist_2.points + matchday5.duelist_2.points + matchday6.duelist_2.points 
      }

      if (userTeam.new_transfers.includes(userTeam.initiator_1.name)) {
        defaultTeam.initiator_1.player_name = userTeam.initiator_1.name
        defaultTeam.initiator_1.k = matchday4.initiator_1.k + matchday5.initiator_1.k + matchday6.initiator_1.k
        defaultTeam.initiator_1.d = matchday4.initiator_1.d + matchday5.initiator_1.d + matchday6.initiator_1.d
        defaultTeam.initiator_1.a = matchday4.initiator_1.a + matchday5.initiator_1.a + matchday6.initiator_1.a
        defaultTeam.initiator_1.fd = matchday4.initiator_1.fd + matchday5.initiator_1.fd + matchday6.initiator_1.fd
        defaultTeam.initiator_1.fk = matchday4.initiator_1.fk + matchday5.initiator_1.fk + matchday6.initiator_1.fk
        defaultTeam.initiator_1.points = matchday4.initiator_1.points + matchday5.initiator_1.points + matchday6.initiator_1.points
  
      } else {
        defaultTeam.initiator_1.player_name = userTeam.initiator_1.name
        defaultTeam.initiator_1.k = matchday1.initiator_1.k + matchday2.initiator_1.k + matchday3.initiator_1.k + matchday4.initiator_1.k + matchday5.initiator_1.k + matchday6.initiator_1.k 
        defaultTeam.initiator_1.d = matchday1.initiator_1.d + matchday2.initiator_1.d + matchday3.initiator_1.d + matchday4.initiator_1.d + matchday5.initiator_1.d + matchday6.initiator_1.d 
        defaultTeam.initiator_1.a = matchday1.initiator_1.a + matchday2.initiator_1.a + matchday3.initiator_1.a + matchday4.initiator_1.a + matchday5.initiator_1.a + matchday6.initiator_1.a 
        defaultTeam.initiator_1.fd = matchday1.initiator_1.fd + matchday2.initiator_1.fd + matchday3.initiator_1.fd + matchday4.initiator_1.fd + matchday5.initiator_1.fd + matchday6.initiator_1.fd 
        defaultTeam.initiator_1.fk = matchday1.initiator_1.fk + matchday2.initiator_1.fk + matchday3.initiator_1.fk + matchday4.initiator_1.fk + matchday5.initiator_1.fk + matchday6.initiator_1.fk 
        defaultTeam.initiator_1.points = matchday1.initiator_1.points + matchday2.initiator_1.points + matchday3.initiator_1.points + matchday4.initiator_1.points + matchday5.initiator_1.points + matchday6.initiator_1.points
      }

      if (userTeam.new_transfers.includes(userTeam.initiator_2.name)) {
        defaultTeam.initiator_2.player_name = userTeam.initiator_2.name
        defaultTeam.initiator_2.k = matchday4.initiator_2.k + matchday5.initiator_2.k + matchday6.initiator_2.k
        defaultTeam.initiator_2.d = matchday4.initiator_2.d + matchday5.initiator_2.d + matchday6.initiator_2.d
        defaultTeam.initiator_2.a = matchday4.initiator_2.a + matchday5.initiator_2.a + matchday6.initiator_2.a
        defaultTeam.initiator_2.fd = matchday4.initiator_2.fd + matchday5.initiator_2.fd + matchday6.initiator_2.fd
        defaultTeam.initiator_2.fk = matchday4.initiator_2.fk + matchday5.initiator_2.fk + matchday6.initiator_2.fk
        defaultTeam.initiator_2.points = matchday4.initiator_2.points + matchday5.initiator_2.points + matchday6.initiator_2.points  
      } else {
        defaultTeam.initiator_2.player_name = userTeam.initiator_2.name
        defaultTeam.initiator_2.k = matchday1.initiator_2.k + matchday2.initiator_2.k + matchday3.initiator_2.k + matchday4.initiator_2.k + matchday5.initiator_2.k + matchday6.initiator_2.k 
        defaultTeam.initiator_2.d = matchday1.initiator_2.d + matchday2.initiator_2.d + matchday3.initiator_2.d + matchday4.initiator_2.d + matchday5.initiator_2.d + matchday6.initiator_2.d 
        defaultTeam.initiator_2.a = matchday1.initiator_2.a + matchday2.initiator_2.a + matchday3.initiator_2.a + matchday4.initiator_2.a + matchday5.initiator_2.a + matchday6.initiator_2.a
        defaultTeam.initiator_2.fd = matchday1.initiator_2.fd + matchday2.initiator_2.fd + matchday3.initiator_2.fd + matchday4.initiator_2.fd + matchday5.initiator_2.fd + matchday6.initiator_2.fd 
        defaultTeam.initiator_2.fk = matchday1.initiator_2.fk + matchday2.initiator_2.fk + matchday3.initiator_2.fk + matchday4.initiator_2.fk + matchday5.initiator_2.fk + matchday6.initiator_2.fk 
        defaultTeam.initiator_2.points = matchday1.initiator_2.points + matchday2.initiator_2.points + matchday3.initiator_2.points + matchday4.initiator_2.points + matchday5.initiator_2.points + matchday6.initiator_2.points 
      }

      if (userTeam.new_transfers.includes(userTeam.sentinel_1.name)) {
        defaultTeam.sentinel_1.player_name = userTeam.sentinel_1.name
        defaultTeam.sentinel_1.k = matchday4.sentinel_1.k + matchday5.sentinel_1.k + matchday6.sentinel_1.k
        defaultTeam.sentinel_1.d = matchday4.sentinel_1.d + matchday5.sentinel_1.d + matchday6.sentinel_1.d
        defaultTeam.sentinel_1.a = matchday4.sentinel_1.a + matchday5.sentinel_1.a + matchday6.sentinel_1.a
        defaultTeam.sentinel_1.fd = matchday4.sentinel_1.fd + matchday5.sentinel_1.fd + matchday6.sentinel_1.fd
        defaultTeam.sentinel_1.fk = matchday4.sentinel_1.fk + matchday5.sentinel_1.fk + matchday6.sentinel_1.fk
        defaultTeam.sentinel_1.points = matchday4.sentinel_1.points + matchday5.sentinel_1.points + matchday6.sentinel_1.points  
      } else {
        defaultTeam.sentinel_1.player_name = userTeam.sentinel_1.name
        defaultTeam.sentinel_1.k = matchday1.sentinel_1.k + matchday2.sentinel_1.k + matchday3.sentinel_1.k + matchday4.sentinel_1.k + matchday5.sentinel_1.k + matchday6.sentinel_1.k 
        defaultTeam.sentinel_1.d = matchday1.sentinel_1.d + matchday2.sentinel_1.d + matchday3.sentinel_1.d + matchday4.sentinel_1.d + matchday5.sentinel_1.d + matchday6.sentinel_1.d
        defaultTeam.sentinel_1.a = matchday1.sentinel_1.a + matchday2.sentinel_1.a + matchday3.sentinel_1.a + matchday4.sentinel_1.a + matchday5.sentinel_1.a + matchday6.sentinel_1.a 
        defaultTeam.sentinel_1.fd = matchday1.sentinel_1.fd + matchday2.sentinel_1.fd + matchday3.sentinel_1.fd + matchday4.sentinel_1.fd + matchday5.sentinel_1.fd + matchday6.sentinel_1.fd
        defaultTeam.sentinel_1.fk = matchday1.sentinel_1.fk + matchday2.sentinel_1.fk + matchday3.sentinel_1.fk + matchday4.sentinel_1.fk + matchday5.sentinel_1.fk + matchday6.sentinel_1.fk
        defaultTeam.sentinel_1.points = matchday1.sentinel_1.points + matchday2.sentinel_1.points + matchday3.sentinel_1.points + matchday4.sentinel_1.points + matchday5.sentinel_1.points + matchday6.sentinel_1.points
      }

      if (userTeam.new_transfers.includes(userTeam.sentinel_2.name)) {
        defaultTeam.sentinel_2.player_name = userTeam.sentinel_2.name
        defaultTeam.sentinel_2.k = matchday4.sentinel_2.k + matchday5.sentinel_2.k + matchday6.sentinel_2.k
        defaultTeam.sentinel_2.d = matchday4.sentinel_2.d + matchday5.sentinel_2.d + matchday6.sentinel_2.d
        defaultTeam.sentinel_2.a = matchday4.sentinel_2.a + matchday5.sentinel_2.a + matchday6.sentinel_2.a
        defaultTeam.sentinel_2.fd = matchday4.sentinel_2.fd + matchday5.sentinel_2.fd + matchday6.sentinel_2.fd
        defaultTeam.sentinel_2.fk = matchday4.sentinel_2.fk + matchday5.sentinel_2.fk + matchday6.sentinel_2.fk
        defaultTeam.sentinel_2.points = matchday4.sentinel_2.points + matchday5.sentinel_2.points + matchday6.sentinel_2.points
      } else {
        defaultTeam.sentinel_2.player_name = userTeam.sentinel_2.name
        defaultTeam.sentinel_2.k = matchday1.sentinel_2.k + matchday2.sentinel_2.k + matchday3.sentinel_2.k + matchday4.sentinel_2.k + matchday5.sentinel_2.k + matchday6.sentinel_2.k
        defaultTeam.sentinel_2.d = matchday1.sentinel_2.d + matchday2.sentinel_2.d + matchday3.sentinel_2.d + matchday4.sentinel_2.d + matchday5.sentinel_2.d + matchday6.sentinel_2.d
        defaultTeam.sentinel_2.a = matchday1.sentinel_2.a + matchday2.sentinel_2.a + matchday3.sentinel_2.a + matchday4.sentinel_2.a + matchday5.sentinel_2.a + matchday6.sentinel_2.a 
        defaultTeam.sentinel_2.fd = matchday1.sentinel_2.fd + matchday2.sentinel_2.fd + matchday3.sentinel_2.fd + matchday4.sentinel_2.fd + matchday5.sentinel_2.fd + matchday6.sentinel_2.fd 
        defaultTeam.sentinel_2.fk = matchday1.sentinel_2.fk + matchday2.sentinel_2.fk + matchday3.sentinel_2.fk + matchday4.sentinel_2.fk + matchday5.sentinel_2.fk + matchday6.sentinel_2.fk 
        defaultTeam.sentinel_2.points = matchday1.sentinel_2.points + matchday2.sentinel_2.points + matchday3.sentinel_2.points + matchday4.sentinel_2.points + matchday5.sentinel_2.points + matchday6.sentinel_2.points  
      }

      if (matchday1.bench_1.player_name !== "") {
        defaultTeam.bench_1.player_name = userTeam.bench_1.name
        defaultTeam.bench_1.k = matchday1.bench_1.k + matchday2.bench_1.k + matchday3.bench_1.k
        defaultTeam.bench_1.d = matchday1.bench_1.d + matchday2.bench_1.d + matchday3.bench_1.d
        defaultTeam.bench_1.a = matchday1.bench_1.a + matchday2.bench_1.a + matchday3.bench_1.a
        defaultTeam.bench_1.fd = matchday1.bench_1.fd + matchday2.bench_1.fd + matchday3.bench_1.fd
        defaultTeam.bench_1.fk = matchday1.bench_1.fk + matchday2.bench_1.fk + matchday3.bench_1.fk
        defaultTeam.bench_1.points = matchday1.bench_1.points + matchday2.bench_1.points + matchday3.bench_1.points
      }
      if (matchday1.bench_2.player_name !== "") {
        defaultTeam.bench_2.player_name = userTeam.bench_2.name
        defaultTeam.bench_2.k = matchday1.bench_2.k + matchday2.bench_2.k + matchday3.bench_2.k
        defaultTeam.bench_2.d = matchday1.bench_2.d + matchday2.bench_2.d + matchday3.bench_2.d
        defaultTeam.bench_2.a = matchday1.bench_2.a + matchday2.bench_2.a + matchday3.bench_2.a
        defaultTeam.bench_2.fd = matchday1.bench_2.fd + matchday2.bench_2.fd + matchday3.bench_2.fd
        defaultTeam.bench_2.fk = matchday1.bench_2.fk + matchday2.bench_2.fk + matchday3.bench_2.fk
        defaultTeam.bench_2.points = matchday1.bench_2.points + matchday2.bench_2.points + matchday3.bench_2.points
      }
      if (matchday1.bench_3.player_name !== "") {
        defaultTeam.bench_3.player_name = userTeam.bench_3.name
        defaultTeam.bench_3.k = matchday1.bench_3.k + matchday2.bench_3.k + matchday3.bench_3.k
        defaultTeam.bench_3.d = matchday1.bench_3.d + matchday2.bench_3.d + matchday3.bench_3.d
        defaultTeam.bench_3.a = matchday1.bench_3.a + matchday2.bench_3.a + matchday3.bench_3.a
        defaultTeam.bench_3.fd = matchday1.bench_3.fd + matchday2.bench_3.fd + matchday3.bench_3.fd
        defaultTeam.bench_3.fk = matchday1.bench_3.fk + matchday2.bench_3.fk + matchday3.bench_3.fk
        defaultTeam.bench_3.points = matchday1.bench_3.points + matchday2.bench_3.points + matchday3.bench_3.points
      }

      setTotalStat(defaultTeam)
    }
  }, [matchday1, matchday2, matchday3, matchday4, matchday5, matchday6, userTeam])

  return (
    <div
      style={{
        backgroundColor: "#0e1822",
        backgroundSize: "cover",
        minHeight: "100vh", height: "100%", width: "100vw"
      }}
    >
      <Banner/>
      <br />
      <div style={{ float: "left" , paddingLeft: "15%"}}>
        
      </div>

      <div style={{ paddingLeft: "15%", paddingRight: "15%" }}>
      <Card style={{ width: "50%" }} bg="dark" text="white" border="secondary">
        {/* <Card.Title as="h5">Team Name: </Card.Title> */}
          <Card.Body style={{ height: 50 }}>
            <Card.Text>
              <div style={{ fontSize: 20, display: "flex", justifyContent: "center", alignItems: "center", height: "10px" }}>
                {userTeamName}
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
        <Tabs defaultActiveKey="0" id="dashboard" className="mb-3">
          <Tab eventKey="0" title="Total">
            <StatTable istotal={true} teamdata={totalStat} userteam={props.userTeam} mw={0} newtransfers={newTransfers}/>
          </Tab>
          <Tab eventKey="1" title="Match 1">
            <StatTable istotal={false} teamdata={matchday1} userteam={props.userTeam} mw={1} newtransfers={newTransfers}/>
          </Tab>
          <Tab eventKey="2" title="Match 2">
            <StatTable istotal={false} teamdata={matchday2} userteam={props.userTeam} mw={2} newtransfers={newTransfers}/>
          </Tab>
          <Tab eventKey="3" title="Match 3">
            <StatTable istotal={false} teamdata={matchday3} userteam={props.userTeam} mw={3} newtransfers={newTransfers}/>
          </Tab>
          <Tab eventKey="4" title="Match 4">
            <StatTable istotal={false} teamdata={matchday4} userteam={props.userTeam} mw={4} newtransfers={newTransfers}/>
          </Tab>
          <Tab eventKey="5" title="Match 5">
            <StatTable istotal={false} teamdata={matchday5} userteam={props.userTeam} mw={5} newtransfers={newTransfers}/>
          </Tab>
          <Tab eventKey="6" title="Playoffs">
            <StatTable istotal={false} teamdata={matchday6} userteam={props.userTeam} mw={6} newtransfers={newTransfers}/>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}