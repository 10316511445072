import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import { Button, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getPlayerList,
} from "../components/Firebase";
import { getImageLink } from "../components/SearchPlayers";
import Image from "react-bootstrap/Image";

export default function PlayerPointsBoard(props) {
  const [playerListByPoints, setPlayerListByPoints] = useState([]);
  const [sortByPoints, setSortByPoints] = useState(true);

  const [playerListByValue, setPlayerListByValue] = useState([]);
  const [sortByValue, setSortByValue] = useState(false);

  const [playerListByPicked, setPlayerListByPicked] = useState([])
  const [sortByPicked, setSortByPicked] = useState(false);
  
  const [playerListByPrice, setPlayerListByPrice] = useState([])
  const [sortByPrice, setSortByPriced] = useState(false)
  // const DICT = {
  //   Marved: 149,
  //   yay: 133,
  //   Cryocells: 131,
  //   zander: 119,
  //   Asuna: 104,
  //   BabyJ: 102,
  //   trent: 95,
  //   mitch: 87,
  //   Ethan: 84,
  //   YaBoiDre: 82,
  //   TenZ: 81,
  //   leaf: 78,
  //   dapr: 75,
  //   crashies: 74,
  //   neT: 74,
  //   vanity: 72,
  //   ANDROID: 59,
  //   Xeppaa: 57,
  //   Zellsis: 57,
  //   supamen: 55,
  //   Derrek: 52,
  //   zekken: 49,
  //   ShahZaM: 48,
  //   SicK: 44,
  //   dephh: 44,
  //   skuba: 40,
  //   FNS: 37,
  //   zombs: 34,
  //   wippie: 26,
  //   AYRIN: 20,
  //   s0m: 20,
  //   xeta: 20,
  //   ec1s: 19,
  //   BcJ: 16,
  //   Hiko: 16,
  //   moose: 15,
  //   Victor: 12,
  //   valyn: 12,
  //   POISED: 11,
  //   eeiu: 10,
  //   Sayaplayer: 9,
  //   mada: 9,
  //   hazed: 8,
  //   penny: 7,
  //   JonahP: 6,
  //   effys: 5,
  //   pho: 5,
  //   Reformed: 4,
  //   XXiF: 4,
  //   Frosty: 3,
  //   Genghsta: 3,
  //   TiGG: 3,
  //   tex: 2,
  //   Shanks: 1,
  //   bdog: 1,
  //   jawgemo: 1,
  //   Boostio: 0,
  //   ban: 0,
  //   dazzLe: 0,
  //   neptune: 0,
  // };

  

  useEffect(() => {
    async function fetchData() {
      var list1 = await getPlayerList();
      list1.sort(function (a, b) {
        return b.total_points - a.total_points;
      });
      setPlayerListByPoints(list1);

      var list2 = await getPlayerList();
      list2.sort(function (a, b) {
        return b.total_points / b.price - a.total_points / a.price;
      });
      setPlayerListByValue(list2);

      var list3 = await getPlayerList();
      list3.sort(function (a, b) {
        return b.picked - a.picked
      });
      setPlayerListByPicked(list3)

      var list4 = await getPlayerList();
      list4.sort(function (a, b) {
        return b.price - a.price
      });
      setPlayerListByPrice(list4)
    }
    fetchData();
  }, []);

  function updateSortByPoints() {
    setSortByPoints(true); 
    setSortByValue(false);
    setSortByPicked(false);
    setSortByPriced(false);

  }

  function updateSortByValue() {
    setSortByPoints(false);
    setSortByValue(true);
    setSortByPicked(false);
    setSortByPriced(false);

  }

  function updateSortByPicked() {
    setSortByPoints(false);
    setSortByValue(false);
    setSortByPicked(true);
    setSortByPriced(false);
  }

  function updateSortByPrice() {
    setSortByPoints(false);
    setSortByValue(false);
    setSortByPicked(false);
    setSortByPriced(true);
  }

  function showRoles(player) {
    let roles = player.roles
    if (roles.length === 1) {
      return (
        <td>
          <Image
          style={{ width: 30, height: 30 }}
          src={getImageLink(player.roles[0])}
          />
        </td>
      )
    } else if (roles.length === 2) {
      return (
        <td>
          <Image
          style={{ width: 30, height: 30 }}
          src={getImageLink(player.roles[0])}
          />
          {" / "}
          <Image
          style={{ width: 30, height: 30 }}
          src={getImageLink(player.roles[1])}
          />
        </td>
      )
    }
    
  }

  var sortedByPickedBoard = playerListByPicked.map((player) => {
    return (
      <tr>
      <td>{playerListByPicked.indexOf(player) + 1}</td>
      <td>
        <Image
          style={{ width: 30, height: 30 }}
          src={getImageLink(player.team_name)}
        />
        {" " + player.player_name}
      </td>
      {showRoles(player)}
      <td>{player.total_points}</td>
      <td>{player.picked}</td>
      <td>{player.price}</td>
      <td>{Math.round((100 * player.total_points) / player.price) / 100}</td>
      </tr>
    );
  });

  var sortedByPointsBoard = playerListByPoints.map((player) => {
    return (
      <tr>
        <td>{playerListByPoints.indexOf(player) + 1}</td>
        <td>
          <Image
            style={{ width: 30, height: 30 }}
            src={getImageLink(player.team_name)}
          />
          {" " + player.player_name}
        </td>
        {showRoles(player)}
        <td>{player.total_points}</td>
        <td>{player.picked}</td>
        <td>{player.price}</td>
        <td>{Math.round((100 * player.total_points) / player.price) / 100}</td>
      </tr>
    );
  });

  var sortedByValueBoard = playerListByValue.map((player) => {
    return (
      <tr>
        <td>{playerListByValue.indexOf(player) + 1}</td>
        <td>
          <Image
            style={{ width: 30, height: 30 }}
            src={getImageLink(player.team_name)}
          />
          {" " + player.player_name}
        </td>
        {showRoles(player)}
        <td>{player.total_points}</td>
        <td>{player.picked}</td>
        <td>{player.price}</td>
        <td>{Math.round((100 * player.total_points) / player.price) / 100}</td>
      </tr>
    );
  });

  var sortedByPriceBoard = playerListByPrice.map((player) => {
    return (
      <tr>
        <td>{playerListByPrice.indexOf(player) + 1}</td>
        <td>
          <Image
            style={{ width: 30, height: 30 }}
            src={getImageLink(player.team_name)}
          />
          {" " + player.player_name + "   "}
        </td>
        {showRoles(player)}
        <td>{player.total_points}</td>
        <td>{player.picked}</td>
        <td>{player.price}</td>
        <td>{Math.round((100 * player.total_points) / player.price) / 100}</td>
      </tr>
    )
  })

  return (
    <div
      style={{
        backgroundColor: "#0e1822",
        backgroundSize: "cover",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Banner />
      <br />

      <div style={{ paddingLeft: "15%", paddingRight: "15%" }}>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th width="50">Rank</th>
              <th>Player</th>
              <th width="100">Roles</th>
              <th width="100">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={
                    <Tooltip id="button-tooltip" {...props}>
                      Click to sort
                    </Tooltip>
                  }
                >
                  {<Button variant = {sortByPoints ? "light" : "outline-secondary"}onClick={() => updateSortByPoints()}>Points</Button>}
                </OverlayTrigger>
              </th>
              <th width="100">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={
                    <Tooltip id="button-tooltip" {...props}>
                      Click to sort
                    </Tooltip>
                  }
                >
                  {<Button variant = {sortByPicked ? "light" : "outline-secondary"} onClick={() => updateSortByPicked()}># Picked</Button>}
                </OverlayTrigger>
              </th>
              <th width="100">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={
                    <Tooltip id="button-tooltip" {...props}>
                      Click to sort
                    </Tooltip>
                  }
                >
                  {<Button variant = {sortByPrice ? "light" : "outline-secondary"}onClick={() => updateSortByPrice()}>Price</Button>}
                </OverlayTrigger>
              
              </th>
              <th width="150">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={
                    <Tooltip id="button-tooltip" {...props}>
                      Click to sort
                    </Tooltip>
                  }
                >
                  {<Button variant = {sortByValue ? "light" : "outline-secondary"}onClick={() => updateSortByValue()}>Points / Price</Button>}
                </OverlayTrigger>
              
              </th>
            </tr>
          </thead>
          <tbody>
            {sortByPoints && sortedByPointsBoard}
            {sortByValue && sortedByValueBoard}
            {sortByPicked && sortedByPickedBoard}
            {sortByPrice && sortedByPriceBoard}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
