import React from "react";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getImageLink } from "./SearchPlayers";

export default function PlayerRow(props) {
  
  return (
    <>
      {props.istotal ? (
        <tr>
          <td>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {props.rolename}
                </Tooltip>
              }
            >
              <Image style={{ width: 30, height: 30 }} src={props.imagelink} />
            </OverlayTrigger>
          </td>
          <td>
            <Image style={{ width: 30, height: 30}} src={getImageLink(props.team)}/>
            {" " + props.player.player_name}
          </td>
          <td>{props.player.k}</td>
          <td>{props.player.d}</td>
          <td>{props.player.a}</td>
          <td>{props.player.fk}</td>
          <td>{props.player.fd}</td>
          <td>{props.player.points}</td>
        </tr>
      ) : (
        <tr>
          <td>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {props.rolename}
                </Tooltip>
              }
            >
              <Image style={{ width: 30, height: 30 }} src={props.imagelink} />
            </OverlayTrigger>
          </td>
          <td>
            <Image style={{ width: 30, height: 30}} src={getImageLink(props.team)}/>
            {" " + props.player.player_name}
          </td>
          <td>{props.player.k}</td>
          <td>{props.player.d}</td>
          <td>{props.player.a}</td>
          <td>{props.player.fk}</td>
          <td>{props.player.fd}</td>
          <td>{props.player.adr}</td>
          <td>{props.player.points}</td>
        </tr>
      )}
    </>
  );
}
