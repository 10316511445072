import React, { useContext, useEffect, useState } from "react";
import PlayerRow from "./PlayerRow";
// import BenchPlayerRow from "./BenchPlayerRow";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
// import { updateUserPoints } from "./Firebase";
import { UserContext } from "../provider/UserProvider";

export default function StatTable(props) {

  const user = useContext(UserContext)

  var defaultTeam = {
    duelist_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    duelist_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    controller_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    controller_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    initiator_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    initiator_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    sentinel_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    sentinel_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 }  ,
    bench_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    bench_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    bench_3: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 }
  }



  const [team, setTeam] = useState(defaultTeam)
  const [totalPoints, setTotalPoints] = useState(0)
  //const [userteam, setuserteam] = useState(props.userteam)
  const [duelist_1_team, setDuelist_1_team] = useState()
  const [duelist_2_team, setDuelist_2_team] = useState()
  const [initiator_1_team, setInitiator_1_team] = useState()
  const [initiator_2_team, setInitiator_2_team] = useState()
  const [controller_1_team, setController_1_team] = useState()
  const [controller_2_team, setController_2_team] = useState()
  const [sentinel_1_team, setSentinel_1_team] = useState()
  const [sentinel_2_team, setSentinel_2_team] = useState()
  // const [bench_1_team, setBench_1_team] = useState()
  // const [bench_2_team, setBench_2_team] = useState()
  // const [bench_3_team, setBench_3_team] = useState()
  // const [bench1Exists, setBench1Exists] = useState(false)
  // const [bench2Exists, setBench2Exists] = useState(false)
  // const [bench3Exists, setBench3Exists] = useState(false)
  // const [newTransfers, setNewTransers] = useState([])


  useEffect(() => {
    if (props.teamdata !== null && props.newtransfers !== null) {
      const team = props.teamdata
      setTeam(props.teamdata)
      const newTransfers = props.newtransfers
      // setNewTransers(props.newtransfers)
      var pointSum = 
      team.duelist_1.points +
        team.duelist_2.points +
        team.controller_1.points +
        team.controller_2.points +
        team.initiator_1.points +
        team.initiator_2.points +
        team.sentinel_1.points +
        team.sentinel_2.points

      
      if (props.mw === 0) {
        //add all points
        if (team.bench_1.player_name !== "") {
          // setBench1Exists(true)
          pointSum += team.bench_1.points
        }
        if (team.bench_2.player_name !== "") {
          // setBench2Exists(true)
          pointSum += team.bench_2.points
        }
        if (team.bench_3.player_name !== "") {
          // setBench3Exists(true)
          pointSum += team.bench_3.points
        }
      } else if (props.mw > 3) {
        //no bench points
        if (team.bench_1.player_name !== "") {
          // setBench1Exists(true)
        }
        if (team.bench_2.player_name !== "") {
          // setBench2Exists(true)
        }
        if (team.bench_3.player_name !== "") {
          // setBench3Exists(true)
        }
      } else if (props.mw <= 3) {
        //add bench points
        if (team.bench_1.player_name !== "") {
          // setBench1Exists(true)
          pointSum += team.bench_1.points
        }
        if (team.bench_2.player_name !== "") {
          // setBench2Exists(true)
          pointSum += team.bench_2.points
        }
        if (team.bench_3.player_name !== "") {
          // setBench3Exists(true)
          pointSum += team.bench_3.points
        }

        //don't add new transfer points
        //console.log(newTransfers)
        if (newTransfers.includes(team.controller_1.player_name)) {
          pointSum -= team.controller_1.points
        }
        if (newTransfers.includes(team.controller_2.player_name)) {
          pointSum -= team.controller_2.points
        }
        if (newTransfers.includes(team.duelist_1.player_name)) {
          pointSum -= team.duelist_1.points
        }
        if (newTransfers.includes(team.duelist_2.player_name)) {
          pointSum -= team.duelist_2.points
        }
        if (newTransfers.includes(team.initiator_1.player_name)) {
          pointSum -= team.initiator_1.points
        }
        if (newTransfers.includes(team.initiator_2.player_name)) {
          pointSum -= team.initiator_2.points
        }
        if (newTransfers.includes(team.sentinel_1.player_name)) {
          pointSum -= team.sentinel_1.points
        }
        if (newTransfers.includes(team.sentinel_2.player_name)) {
          pointSum -= team.sentinel_2.points
        }
      }
      

      if (team.bench_1.player_name !== "") {
        // setBench1Exists(true)
      }
      if (team.bench_2.player_name !== "") {
        // setBench2Exists(true)
      }
      if (team.bench_3.player_name !== "") {
        // setBench3Exists(true)
      }
      setTotalPoints(pointSum)
      // if (props.istotal && pointSum !== 0) {
      //   updateUserPoints(user, pointSum)
      // }
    }
  }, [props.teamdata, props.istotal, user, props.newtransfers, props.mw])

  useEffect(() => {
    if (props.userteam !== null && props.userteam !== undefined) {
      //setuserteam(props.userteam)
      setDuelist_1_team(props.userteam.duelist_1.team)
      setDuelist_2_team(props.userteam.duelist_2.team)
      setInitiator_1_team(props.userteam.initiator_1.team)
      setInitiator_2_team(props.userteam.initiator_2.team)
      setController_1_team(props.userteam.controller_1.team)
      setController_2_team(props.userteam.controller_2.team)
      setSentinel_1_team(props.userteam.sentinel_1.team)
      setSentinel_2_team(props.userteam.sentinel_2.team)
      if (props.userteam.bench_1) {
        // setBench_1_team(props.userteam.bench_1.team)
      }
      if (props.userteam.bench_2) {
        // setBench_2_team(props.userteam.bench_2.team)
      }
      if (props.userteam.bench_3) {
        // setBench_3_team(props.userteam.bench_3.team)
      }
    }
  }, [props.userteam])


  return (
    <div
      style={{
        backgroundColor: "#0e1822",
        backgroundSize: "cover",
        minHeight: "150vh", height: "100%"
      }}>
      <Table striped bordered hover variant="dark">
        <thead>
          {props.istotal ? (
            <tr>
              <th width="50">Role</th>
              <th width="500">Player</th>
              <th width="100">Kills</th>
              <th width="100">Deaths</th>
              <th width="100">Assists</th>
              <th width="100">FK</th>
              <th width="100">FD</th>
              <th>Points</th>
            </tr>
          ) : (
            <tr>
              <th width="50">Role</th>
              <th width="400">Player</th>
              <th width="100">Kills</th>
              <th width="100">Deaths</th>
              <th width="100">Assists</th>
              <th width="100">FK</th>
              <th width="100">FD</th>
              <th width="100">ADR</th>
              <th>Points</th>
            </tr>
          )}
        </thead>
        <tbody>
          <PlayerRow
            imagelink="https://static.wikia.nocookie.net/valorant/images/f/fd/DuelistClassSymbol.png"
            rolename="Duelist"
            istotal={props.istotal}
            player={team.duelist_1}
            team={duelist_1_team}
          />
          <PlayerRow
            imagelink="https://static.wikia.nocookie.net/valorant/images/f/fd/DuelistClassSymbol.png"
            rolename="Duelist"
            istotal={props.istotal}
            player={team.duelist_2}
            team={duelist_2_team}

          />
          <PlayerRow
            imagelink="https://static.wikia.nocookie.net/valorant/images/7/77/InitiatorClassSymbol.png"
            rolename="Initiator"
            istotal={props.istotal}
            player={team.initiator_1}
            team={initiator_1_team}
          />
          <PlayerRow
            imagelink="https://static.wikia.nocookie.net/valorant/images/7/77/InitiatorClassSymbol.png"
            rolename="Initiator"
            istotal={props.istotal}
            player={team.initiator_2}
            team={initiator_2_team}
          />
          <PlayerRow
            imagelink="https://static.wikia.nocookie.net/valorant/images/4/43/SentinelClassSymbol.png"
            rolename="Sentinel"
            istotal={props.istotal}
            player={team.sentinel_1}
            team={sentinel_1_team}
          />
          <PlayerRow
            imagelink="https://static.wikia.nocookie.net/valorant/images/4/43/SentinelClassSymbol.png"
            rolename="Sentinel"
            istotal={props.istotal}
            player={team.sentinel_2}
            team={sentinel_2_team}
          />
          <PlayerRow
            imagelink="https://static.wikia.nocookie.net/valorant/images/0/04/ControllerClassSymbol.png"
            rolename="Controller"
            istotal={props.istotal}
            player={team.controller_1}
            team={controller_1_team}
          />
          <PlayerRow
            imagelink="https://static.wikia.nocookie.net/valorant/images/0/04/ControllerClassSymbol.png"
            rolename="Controller"
            istotal={props.istotal}
            player={team.controller_2}
            team={controller_2_team}
          />
        </tbody>
      </Table>

      {/* <Table striped bordered hover variant="dark">
      <thead>
          {props.istotal ? (
            <tr>
              <th width="50">Role</th>
              <th width="500">Player</th>
              <th width="100">Kills</th>
              <th width="100">Deaths</th>
              <th width="100">Assists</th>
              <th width="100">FK</th>
              <th width="100">FD</th>
              <th>Points</th>
            </tr>
          ) : (
            <tr>
              <th width="50">Role</th>
              <th width="400">Player</th>
              <th width="100">Kills</th>
              <th width="100">Deaths</th>
              <th width="100">Assists</th>
              <th width="100">FK</th>
              <th width="100">FD</th>
              <th width="100">ADR</th>
              <th>Points</th>
            </tr>
          )}
        </thead>
        <tbody>
          <BenchPlayerRow 
            rolename="Bench"
            istotal={props.istotal}
            benchexists={bench1Exists}
            player={team.bench_1}
            team={bench_1_team}
          />
        
          <BenchPlayerRow 
            rolename="Bench"
            istotal={props.istotal}
            benchexists={bench2Exists}
            player={team.bench_2}
            team={bench_2_team}
          />

          <BenchPlayerRow 
            rolename="Bench"
            istotal={props.istotal}
            benchexists={bench3Exists}
            player={team.bench_3}
            team={bench_3_team}
          />
        </tbody>
      </Table> */}

      <div style={{ float: "right" }}>
        <Card style={{ width: "100%" }} bg="dark" text="white" border="secondary">
          <Card.Header as="h5">Total Points</Card.Header>
          <Card.Body style={{ height: 50 }}>
            <Card.Text>
              <div style={{ fontSize: 20, display: "flex", justifyContent: "center", alignItems: "center", height: "10px" }}>
                {totalPoints}
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
