import React, { useEffect, useState } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import { getImageLink } from "../components/SearchPlayers";
import Image from "react-bootstrap/Image";
import { getPoints } from "./Firebase";

export default function TeamPopUp(props) {
  const [showModal, setShowModal] = useState(false);
  const [player1Points, setPlayer1Points] = useState(0);
  const [player2Points, setPlayer2Points] = useState(0);
  const [player3Points, setPlayer3Points] = useState(0);
  const [player4Points, setPlayer4Points] = useState(0);
  const [player5Points, setPlayer5Points] = useState(0);
  const [player6Points, setPlayer6Points] = useState(0);
  const [player7Points, setPlayer7Points] = useState(0);
  const [player8Points, setPlayer8Points] = useState(0);

  var user = props.user;
  var userList = props.userList;

  useEffect(() => {
    async function fetchData() {
      var points1 = await getPoints(user.user_team.duelist_1.name);
      var points2 = await getPoints(user.user_team.duelist_2.name);
      var points3 = await getPoints(user.user_team.initiator_1.name);
      var points4 = await getPoints(user.user_team.initiator_2.name);
      var points5 = await getPoints(user.user_team.sentinel_1.name);
      var points6 = await getPoints(user.user_team.sentinel_2.name);
      var points7 = await getPoints(user.user_team.controller_1.name);
      var points8 = await getPoints(user.user_team.controller_2.name);

      setPlayer1Points(points1);
      setPlayer2Points(points2);
      setPlayer3Points(points3);
      setPlayer4Points(points4);
      setPlayer5Points(points5);
      setPlayer6Points(points6);
      setPlayer7Points(points7);
      setPlayer8Points(points8);
    }
    fetchData();
  }, [
    user.user_team.duelist_1.name,
    user.user_team.duelist_2.name,
    user.user_team.initiator_1.name,
    user.user_team.initiator_2.name,
    user.user_team.sentinel_1.name,
    user.user_team.sentinel_2.name,
    user.user_team.controller_1.name,
    user.user_team.controller_2.name,
  ]);

  return (
    <td>
      {user.display_team_name}
      <Button
        style={{ float: "right" }}
        variant="light"
        size="sm"
        onClick={() => setShowModal(true)}
      >
        View Team
      </Button>

      <Modal
        id={user.display_team_name}
        show={showModal}
        onClick={() => setShowModal(false)}
      >
        <Modal.Header
          style={{
            fontSize: 30,
            backgroundColor: "#0e1822",
            color: "white",
          }}
        >
          <div>{user.display_team_name}</div>
          <div style={{ fontSize: 20 }}>
            Rank {userList.indexOf(user) + 1} of {userList.length}
          </div>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#0e1822" }}>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Role</th>
                <th>Player</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="50">
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink("Duelist")}
                  />
                </td>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink(user.user_team.duelist_1.team)}
                  />
                  {"  " + user.user_team.duelist_1.name}
                </td>
                <td>{player1Points}</td>
              </tr>
              <tr>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink("Duelist")}
                  />
                </td>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink(user.user_team.duelist_2.team)}
                  />
                  {"  " + user.user_team.duelist_2.name}
                </td>
                <td>{player2Points}</td>
              </tr>
              <tr>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink("Initiator")}
                  />
                </td>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink(user.user_team.initiator_1.team)}
                  />
                  {"  " + user.user_team.initiator_1.name}
                </td>
                <td>{player3Points}</td>
              </tr>
              <tr>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink("Initiator")}
                  />
                </td>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink(user.user_team.initiator_2.team)}
                  />
                  {"  " + user.user_team.initiator_2.name}
                </td>
                <td>{player4Points}</td>
              </tr>
              <tr>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink("Sentinel")}
                  />
                </td>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink(user.user_team.sentinel_1.team)}
                  />
                  {"  " + user.user_team.sentinel_1.name}
                </td>
                <td>{player5Points}</td>
              </tr>
              <tr>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink("Sentinel")}
                  />
                </td>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink(user.user_team.sentinel_2.team)}
                  />
                  {"  " + user.user_team.sentinel_2.name}
                </td>
                <td>{player6Points}</td>
              </tr>
              <tr>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink("Controller")}
                  />
                </td>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink(user.user_team.controller_1.team)}
                  />
                  {"  " + user.user_team.controller_1.name}
                </td>
                <td>{player7Points}</td>
              </tr>
              <tr>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink("Controller")}
                  />
                </td>
                <td>
                  <Image
                    style={{ width: 30, height: 30 }}
                    src={getImageLink(user.user_team.controller_2.team)}
                  />
                  {"  " + user.user_team.controller_2.name}
                </td>
                <td>{player8Points}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#0e1822" }}>
          <Button variant="danger" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </td>
  );
}
